@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

$icon-font-path: '../fonts/';

// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;

  /* Make the masthead heading the same height as the navigation */
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    padding-bottom: 19px;
  }
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  .btn {
    font-size: 21px;
    padding: 14px 24px;
  }
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
  p + h4 {
    margin-top: 28px;
  }
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 1100px;
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }

  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

.container {
    padding-left: 20px;
    padding-right: 20px
  }



/* VARIABLES */

$font-family-heading: 'Roboto';
$font-family-paragraph: 'Roboto';
$basic-font-size: 15.4px;  
$color-primary: #6284cb;
$color-secondary: #305473;
$color-footer-1: #192E59;
$color-footer-2: #333333;
$color-headings: #305473;
$color-dark-grey: #3B3B3B;
/* šedá textu 555658  */
/* modrá loga  6284cb  */
/* šedá někam do patičky 444041  */


/* BASICS */

p {
  font-family: $font-family-paragraph;
  font-weight: 300;
  padding: 10px 0px 10px 0px;
}

strong {
  font-family: $font-family-paragraph;
  font-weight: 500;
  padding: 10px 0px 10px 0px;
}

a {
  font-family: $font-family-paragraph;
  padding: 10px 0px 10px 0px;
}

ul {
  li {
    font-family: $font-family-paragraph;
    padding: 10px 0px 10px 0px;
  }
}

h1 {
  font-family: $font-family-heading;
  padding: 10px 0px 10px 0px;
}

h2 {
  font-family: $font-family-heading;
  padding: 10px 0px 10px 0px;
}

h3 {
  font-family: $font-family-heading;
  padding: 10px 0px 10px 0px;
}

h4 {
  font-family: $font-family-heading;
  padding: 10px 0px 10px 0px;
}

.headings-perex {
  
  .left-side {
    height: 320px;
    background-image: url("/wp-content/themes/sage-master/assets/images/heading-sample.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    h2 {
      font-size: 32px;
      font-weight: 800;
      text-align: center;
      color: #fff;
      padding-top: 124px;
    }
  }

  .right-side {
      height: 320px;
      background-color: $color-dark-grey;
      padding-right: 80px;
      padding-left: 80px;

      h4 {
        font-size: $basic-font-size +6px;
        font-weight: 600;
        color: #fff;
        margin: none;
        padding-top: 88px; 
      }

      p {
        font-size: $basic-font-size;
        font-weight: 300;
        line-height: $basic-font-size +10px;
        color: #fff;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
  }
}


/* HEADER */

.contact-strip {
  background-color: $color-dark-grey;

  p {
    font-size: $basic-font-size -2px;
    color: #fff;
    padding-top: 15px;
    padding-bottom: 5px;
  }

  a {
    font-size: $basic-font-size -2px;
    color: #fff;
  }
}

.navigation-strip {
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;

  img {
    width: 52px;
    padding-top: 15px;
  }

.navbar-collapse {
  background-color: white;
  
  ul {
    margin-left: -8px;
    padding-top: 56px;
    margin-right: 10px !important;

    @media (max-width: 768px) {
      padding-top: 0px;
    }

    li {
      text-align: right;
      margin-right: 0px;
      list-style-type: none;
      display: inline;

      a {
        color: $color-dark-grey;
      }

      @media (max-width: 768px) {
              display: block !important;
        }
     }
  }


}

.icon-bar {
  background-color: black;
  height: 3px;
  border-radius: 0px;
}

.navbar-toggle {
  float: left;
}
  
  ul { 
    list-style-type: none;
    padding-top: 24px;
    padding-bottom: 24px;
    margin: 0px 0px 0px 0px;
  
    li {
      display: inline;
      text-transform: uppercase;
      padding: 8px 0px 8px 15px;

      a {
        font-size: $basic-font-size;
        font-weight: 400;
      }

      a:hover {
        color: $color-footer-2;
      }
    }
  }
}

.basic-content {
  padding-bottom: $basic-font-size*3;

  h3 {
    font-size: $basic-font-size +3px;
    font-weight: 600;
    line-height: 22px;
    color: $color-headings;
    padding-top: 27px;
    padding-bottom: 9px;
  }

  h5 {
    font-size: $basic-font-size;
    font-weight: 300;
    color: $color-primary;
    padding-top: $basic-font-size;
    padding-bottom: 0px;
  }

  p {
    font-size: $basic-font-size;
    line-height: $basic-font-size +10px;
    color: #color-dark-grey;
    padding-bottom: 8px;
    padding-top: 8px;
    margin-bottom: 0px !important;
  }

  ul {
    li {
      font-size: $basic-font-size;
      font-weight: 300;
      line-height: $basic-font-size +10px;
      color: #color-dark-grey;
      padding-bottom: 8px;
      padding-top: 8px;
      margin-bottom: 0px !important;
    }
  }
}


/* HOMEPAGE (INDEX) */ 

.homepage-ref {
  
  .box-ref-1 {
    height: 340px;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    h4 {
      padding-top: 88px;
    }
  }

  .box-ref-2 {
    height: 340px;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    h4 {
      padding-top: 88px;
    }
  }

  p {
    font-size: $basic-font-size;
    color: #fff;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-right: 88px;
    padding-left: 88px;
  }

  h3 {
    font-size: 24px;
    font-weight: 800;
    color: #fff;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 26px;
    padding-top: 8px;
  }

  h4 {
    font-size: $basic-font-size;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: $basic-font-size;
    padding-top: 48px;
  }
}

.homepage-main-image {
  background-image: url("/wp-content/themes/sage-master/assets/images/homepage-image.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: auto;

          @media (max-width: 768px) {
              background-size: 100% auto;
        }

  h4 {
    font-size: 26px;
    font-weight: 800;
    color: #fff;   
    text-align: center;
    padding-top: 150px;

          @media (max-width: 768px) {
              padding-top: 30px;
              font-size: 20px;
        }
  }

  button {
    font-size: $basic-font-size;
    font-weight: 700;
    color: #fff; 
    background-color: transparent;
    border: 2px solid #fff;
    padding: 10px 10px 10px 10px;
    text-transform: uppercase;
    font-weight: 800;

    @media (min-width: 769px) {
      margin-bottom: 44px;
    }

    @media (max-width: 768px) {
      margin-bottom: 0px !important;
    }
  }
}

.homepage-desc {
  background-color: #F0F0F0;
  
  h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    color: $color-primary;
    padding: 36px 20% 36px 20%;
  }
}

.homepage-content {
  padding: 48px 0px 48px 0px;

  p {
    font-size: $basic-font-size;
    line-height: 24px;
    color: $color-dark-grey;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: $basic-font-size;
  }

  h3 {
    font-size: $basic-font-size +3px;
    font-weight: 600;
    color: $color-headings;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 18px;
  }
}

.service-boxs { 
  background-color: $color-secondary;
  padding-bottom: 96px;
  padding-top: 48px;

  p {
    font-size: $basic-font-size;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 0px;
    padding: 5px 0px 0px 0px;
  }

  h2 {
    font-size: $basic-font-size;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 30px;
    padding-bottom: $basic-font-size;
    padding-top: 15px;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    font-size: 24px;
    font-weight: 800;
    color: #fff;   
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 26px;
    padding-top: 8px;
  }

  .box-1 {
    height: 180px;
    background-color: $color-secondary;
    padding: 10px 10px 10px 10px;
  }

  .box-2 {
    height: 180px;
    background-color: $color-secondary;
    padding: 10px 10px 10px 10px;
  }

  .box-3 {
    height: 180px;
    background-color: $color-secondary;
    padding: 10px 10px 10px 10px;
  }

  .box-4 {
    height: 180px;
    background-color: $color-secondary;
    padding: 10px 10px 10px 10px;
  }
}

.introduce-text {
  background-color: #F0F0F0;
  
  p {
    font-size: $basic-font-size;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: $color-dark-grey;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: $basic-font-size*2;
    padding-bottom: $basic-font-size*2;
  }
}


/* TEMPLATE-SERVICE*/

.service-page-content {
    padding-bottom: $basic-font-size*3;

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: $color-headings;
      padding-top: 27px;
      padding-bottom: 9px;
    }

    p {
      font-size: $basic-font-size;
      line-height: $basic-font-size +10px;
      color: $color-dark-grey;
      padding-bottom: 8px;
      padding-top: 8px;
    }

    ul {
      li {
        font-size: $basic-font-size;
        line-height: 24px;
        color: $color-dark-grey;
        padding-bottom: 8px;
        padding-top: 8px;
        margin-bottom: 0px !important;
        font-weight: 300 !important;
      }
  }
}


/* TEMPLATE-PARTNERS */

.content-partners-template {
  padding-top: 48px;
  padding-bottom: 66px;

  .img-center {
    text-align: center;
  }

      img {
       margin: 0 auto;
      min-width: 100px;
      max-width: 150px; 
      padding-top: 30px;
    }

  h4 {
    font-size: $basic-font-size;
    font-weight: 300;
    text-align: center;
    padding-bottom: 30px;
  }
}


/* REFERENCES-TEMPLATE */

.content-references-template {
  padding-top: 48px;
  padding-bottom: 66px;

  .img-center {
    margin: 0 auto;
  }

      img {
       margin: 0 auto;
      min-width: 100px;
      max-width: 150px; 
      padding-top: 30px;
    }

  h4 {
    font-size: $basic-font-size;
    font-weight: 300;
    text-align: center;
    padding-bottom: 30px;
  }
}



/* FOOTER  */

.footer-navigation {
  background-color: #3B3B3B;
  padding-bottom: 15px;
  padding-top: 15px;

  ul { 
    list-style-type: none;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
  
    li {
      padding-top: 8px;
      padding-bottom: 8px;

      a {
        font-size: $basic-font-size;
        color: #fff;
        font-weight: 300;
      }

      a:hover {
        font-size:  $basic-font-size;
        color: #fff;
        font-weight: 300;
      }
    }
  }

  p {
    font-size: $basic-font-size;
    line-height: 20px;
    color: #EBEBEB;   
  }

  a {
    font-size: $basic-font-size;
    color: #fff !important;   
  }

  h3 {
    font-size: $basic-font-size;
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 0px;
    padding-top: $basic-font-size;

  }
}

.footer-copyright {
  background-color: $color-footer-2;

  p {
    font-size: $basic-font-size;
    line-height: 0px;
    padding-top: 32px;
    padding-bottom: 16px;
    color: #EBEBEB;
  }

  a {
    font-size: 14px;
    line-height: 0px;
    color: #fff;
    text-decoration: underline;
  }
}